'use strict';

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var _$1 = _interopDefault(require('lodash'));

function arrayMove(array, oldIndex, newIndex) {
  array = array.map(value => value); //HINT clone the array without using es6

  if (newIndex >= array.length) {
    var k = newIndex - array.length + 1;

    while (k--) array.push(undefined);
  }

  array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);

  return array;
}

function findMap(collection1, collection2, predicate) {
  return _$1.filter(_$1.map(collection1, (item1, i1) => {
    return _$1.find(collection2, (item2, i2) => {
      var _predicate = predicate;

      if (typeof(predicate) === 'string') _predicate = (item1, item2) => _$1.get(item1, predicate) === _$1.get(item2, predicate);
      if (Array.isArray(predicate)) _predicate = (item1, item2) => _$1.get(item1, predicate[0]) === _$1.get(item2, predicate[1]);

      return _predicate && _predicate(item1, item2, i1, i2); //HINT avoid "cannot call function undefined"
    });
  }));
}

function labelForMoment(momentInstance, {short=false}={}) {
  var now = new Date();
  var format = short ? 'M/D/YY' : 'M/D/YY h:mma'; //standard long form - m/d/y 00:00am
  var timeDifference = _$1.round(Math.abs(momentInstance.diff(now) / 1000), 0);
  var secondsInOneDay = 60 * 60 * 24;

  if (timeDifference < secondsInOneDay) {
    format = 'h:mma'; // today - 00:00am
  }
  else if (now.getFullYear() === momentInstance.year()) {
    format = short ? 'M/D' : 'M/D h:mma'; // this year - m/d 00:00am
  }

  return momentInstance.format(format);
}

function median(array) {
  const sorted = array.slice().sort((a, b) => a - b);
  const middle = Math.floor(sorted.length / 2);

  if (sorted.length % 2 === 0) {
      return (sorted[middle - 1] + sorted[middle]) / 2;
  }

  return sorted[middle];
}

function objectFromKeys(keys, predicate) {
  var object = {};

  _$1.forEach(keys, (key, index) => object[key] = predicate(key, index));

  return object;
}

//WARNING for some reason mocha + babel doesn't like spread syntax
var _ = Object.assign({}, _$1, {
  arrayMove,
  findMap,
  labelForMoment,
  median,
  objectFromKeys
});

module.exports = _;
