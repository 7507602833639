'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _interopDefault (ex) { return (ex && (typeof ex === 'object') && 'default' in ex) ? ex['default'] : ex; }

var redux = require('redux');
var thunk = _interopDefault(require('redux-thunk'));
var _ = _interopDefault(require('lodash'));
var reactRedux = require('react-redux');
var lib = require('@symbolic/lib');
var UrlPattern = _interopDefault(require('url-pattern'));
var inflection = require('inflection');
var moment = _interopDefault(require('moment'));

function createGenericStore({reducers, initialState={}}={}) {
  var rootReducer = redux.combineReducers({...reducers});

  var store = redux.createStore(
    rootReducer,
    initialState,
    redux.compose(
      redux.applyMiddleware(thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    )
  );

  return store;
}

function reducerAndActionsFor(superType, payloadGetters) {
  var actionsData = _.mapValues(payloadGetters, (getPayload, subType) => {
    return {
      getPayload,
      type: `${superType}.${subType}`,
      isAsync: true//getPayload.constructor === (async function () {}).constructor
    };
  });

  var actions = _.mapValues(actionsData, ({type, getPayload, isAsync}) => {
    // return ({dispatch, isAsync}) => {
      return (props) => {
        return (dispatch, getState) => {
          var prevState = _.get(getState(), superType);

          if (isAsync) {
            (async () => {
              var _getPayload = async () => await getPayload({getState, prevState, [superType]: prevState, ...props});

              dispatch({type: `${type}.begin`, payload: {...prevState, isLoading: true}});

              try {
                var payload = await _getPayload();

                dispatch({type: `${type}.success`, payload: {isLoading: false, ...payload}});
              }
              catch (error) {
                console.error(error); //eslint-disable-line

                dispatch({type: `${type}.failure`, payload: {isLoading: false}});

                throw error;
              }
            })();
          }
          else {
            var _getPayload = () => getPayload({getState, prevState, [superType]: prevState, ...props});

            dispatch({type, payload: _getPayload()});
          }
        };
      };
    // };
  });

  var reducer = (state={}, action) => {
    var {type: t} = action;

    _.forEach(actionsData, ({type}) => {
      if (t === `${type}.begin` || t === `${type}.success` || t === `${type}.failure` || t === type) {
        state = {...action.payload};
      }
    });

    return state;
  };

  return {actions, reducer};
}
function connect({mapState, mapDispatch}) {
  var mapStateToProps = (state, ownProps) => {
    var props = _.pick(state, ['session']);

    if (mapState) {
      if (_.isFunction(mapState)) {
        props = {...props, ...mapState(state, ownProps)};
      }
      else {
        _.forEach(mapState, statePath => {
          props = {...props, [statePath]: _.get(state, statePath)};
        });
      }
    }

    return props;
  };

  var mapDispatchToProps = (dispatch) => {
    var props = {};

    if (mapDispatch) {
      if (_.isFunction(mapDispatch)) {
        props = {...props, ...mapDispatch(dispatch)};
      }
      else {
        props = {...props, ..._.mapValues(mapDispatch, action => {
          return (...args) => dispatch(action(...args));
        })};
      }
    }

    return props;
  };

  return reactRedux.connect(mapStateToProps, mapDispatchToProps);
}

var setToken = async (token) => typeof(sessionStore) !== 'undefined' && await sessionStore.setToken(token);
var removeToken = async () => typeof(sessionStore) !== 'undefined' && await sessionStore.removeToken();
var setActiveOrgId = async (activeOrgId) => typeof(sessionStore) !== 'undefined' && await sessionStore.set('activeOrgId', activeOrgId);

//TODO handle invalid username/password
var tokenFor = async ({email, password, device}) => {
  var tokenResponse = await lib.api.request({uri: '/auth/token', body: {email, password, ...(device ? {device} : {})}}, {shouldAlert: false});
  var token = tokenResponse.data.token;

  setToken(token);

  return token;
};

var filterOrgs = (orgs) => _.filter(orgs, org => APP_KEY === 'designEngine' ? (org.type === 'business' && _.get(org, 'appData.designEngine.isSupported', 0) === 1) : orgs);

//TODO handle invalid token, invalid user
var userAndOrgsFor = async ({token}) => {
  var productOrderOrgSpecificId = null;

  if (APP_KEY === 'designEngine' && window.location) {
    var activeProductOrderIdPattern = new UrlPattern('/orders/:productOrderOrgSpecificId(/*)');

    if (activeProductOrderIdPattern.match(window.location.pathname)) {
      ({productOrderOrgSpecificId} = activeProductOrderIdPattern.match(window.location.pathname));
    }
  }

  var {data} = await lib.api.request({uri: '/user-for-token', body: {token, productOrderOrgSpecificId}});

  return data;
};

var activeOrgFor = async ({activeOrgId: id, orgs}) => {
  var orgs = filterOrgs(orgs);

  var activeOrg = _.find(orgs, {id}) || (APP_KEY === 'designEngine' ? orgs[0] : _.find(orgs, {type: 'personal'}));

  if (!activeOrg && APP_KEY === 'designEngine') {
    alert('Please request access to the configurator');

    throw new Error('Please request access to the configurator');
  }

  await setActiveOrgId(activeOrg.id);

  return {activeOrg, orgs};
};

var {reducer, actions} = reducerAndActionsFor('session', {
  logIn: async ({token, email, password, activeOrgId, device}) => {
    var error;

    try {
      if (!token && email) {
        token = await tokenFor({email, password, device});
      }

      var {user, orgs} = await userAndOrgsFor({token});

      var {activeOrg, orgs} = await activeOrgFor({activeOrgId, orgs});
    }
    catch (e) {
      error = e; //TODO parse data
    }

    //WARNING not sure if this is necessary, but keeping it as it was there before
    if (!error && !user) error = new Error('Something went wrong');

    if (error) {
      if (!_.includes(error.message, 'There was an issue connecting to our server')) setTimeout(() => removeToken()); //HINT intentionally wait to remove token until after log out screen has been shown

      var nextState = {isLoggedIn: false, error};
    }
    else {
      var nextState = {isLoggedIn: true, user, orgs, activeOrg};
    }

    return nextState;
  },
  logOut: async ({shouldRemoveToken=true}={}) => {
    if (shouldRemoveToken) {
      setTimeout(() => removeToken()); //HINT intentionally wait to remove token until after log out screen has been shown
    }

    return {isLoggedIn: false};
  },
  signUp: async ({email, password, firstName, lastName, device}) => {
    var error, nextState;

    //create user
    try {
      await lib.api.request({uri: '/sign-up', body: {firstName, lastName, email, password}}, {shouldAlert: false});

      //logIn
      var token = await tokenFor({email, password, device});
      var {user, orgs} = await userAndOrgsFor({token});

      var {orgs, activeOrg} = await activeOrgFor({orgs});
    }
    catch (e) {
      error = e;
    }

    var nextState = error ? {isLoggedIn: false, error} : {isLoggedIn: true, user, orgs, activeOrg};

    return nextState;
  },
  resetPassword: async ({password, email, oneTimeCode, confirmedPassword, firstName, lastName, device}) => {
    var error;

    try {
      var updateUserResponse = await lib.api.request({
        uri: '/reset-password',
        body: {email, password, confirmPassword: confirmedPassword, firstName, lastName, passwordResetToken: oneTimeCode}
      });

      if (updateUserResponse) {
        var token = await tokenFor({email, password, device});
        var {user, orgs} = await userAndOrgsFor({token});

        var {orgs, activeOrg} = await activeOrgFor({orgs});
      }
    }
    catch (e) {
      error = e;
    }

    var nextState = error ? {isLoggedIn: false, error} : {isLoggedIn: true, user, orgs, activeOrg};

    return nextState;
  },
  updateMyAccount: async ({hue, email, password, firstName, lastName, oldPassword, notificationSettings, expoPushTokenData, firebasePushTokenData, getState}) => {
    var error;
    var prevState = _.get(getState(), `session`);
    var {orgs, activeOrg} = prevState;

    try {
      var token = await global.sessionStore.getToken();
      var updateUserResponse = await lib.api.request({uri: '/update-user', body: {token, props: {hue, email, firstName, lastName, oldPassword, password, notificationSettings}, ...expoPushTokenData, ...firebasePushTokenData}}, {shouldAlert: false});
    }
    catch (e) {
      error = e;
    }

    if (error) {
      alert(error.message);

      var nextState = prevState;
    }
    else {
      var nextState = {isLoggedIn: true, user: _.get(updateUserResponse, 'data.user'), orgs, activeOrg};
    }

    return nextState;
  },
  setProtagonistData: async ({key, value, getState}) => {
    var {user, orgs, activeOrg} = _.get(getState(), `session`);
    var token = await global.sessionStore.getToken();
    var protagonistData = user.protagonistData || {};

    protagonistData[key] = value;

    var updateUserResponse = await lib.api.request({uri: '/update-user', body: {token, props: {protagonistData}}});

    var nextState = {isLoggedIn: true, user: _.get(updateUserResponse, 'data.user'), orgs, activeOrg};

    return nextState;
  },
  setAppData: async ({key, value, getState, appKey, appData}) => {
    var {user, orgs, activeOrg} = _.get(getState(), `session`);
    var token = await global.sessionStore.getToken();

    if (appData) {
      var body = {props: {appData}};
    }
    else {
      var body = {setAppDataKey: `${appKey}.${key}`, setAppDataValue: value};
    }

    var updateUserResponse = await lib.api.request({uri: '/update-user', body: {token, ...body}});

    var nextState = {isLoggedIn: true, user: _.get(updateUserResponse, 'data.user'), orgs, activeOrg};

    return nextState;
  },
  updateUserProps: ({props, getState}) => {
    var prevState = _.get(getState(), `session`);

    return {...prevState, user: {...prevState.user, ...props}};
  },
  updateOrg: async ({id, props, getState, hitApi = true}) => {
    var prevState = _.get(getState(), `session`);
    var orgs = prevState.orgs;
    var org = {..._.find(prevState.orgs, {id}), ...props};

    var nextState = {...prevState, orgs: [..._.reject(orgs, {id}), org]};

    if (_.get(prevState, 'activeOrg.id') === id) {
      nextState.activeOrg = org;
    }

    if (hitApi) await lib.api.update('orgs', {where: {id}, props});

    return nextState;
  },
  updateOrgs: ({orgs, getState}) => {
    var prevState = _.get(getState(), `session`);

    return {...prevState, orgs};
  },
  acceptOrgInvite: async ({orgInviteId, code, getState}) => {
    var nextState = {..._.get(getState(), `session`)};

    try {
      var {data} = await lib.api.request({uri: '/orgs/invites/accept', body: {orgInviteId, code}});
      var newOrg = data.org;

      setActiveOrgId(newOrg.id);

      nextState = {...nextState, orgs: [..._.reject(nextState.orgs, org => org.id === newOrg.id), newOrg], activeOrg: newOrg};
    }
    catch (error) {
      nextState = {...nextState, error};
    }

    return nextState;
  },
  deleteOrg: async ({orgId, getState}) => {
    var nextState = {..._.get(getState(), `session`)};

    try {
      var token = await global.sessionStore.getToken();

      await lib.api.request({uri: '/orgs/delete', body: {token, orgId}});

      var personalOrg = _.find(nextState.orgs, {id: nextState.user.personalOrgId});

      personalOrg = personalOrg || _.find(nextState.orgs, org1 => {
        return org1.id !== orgId;
      });

      setActiveOrg(personalOrg.id);

      nextState = {...nextState, orgs: _.filter(nextState.orgs, org => org.id !== orgId), activeOrg: personalOrg};
    }
    catch (error) {
      nextState = {...nextState, error};
    }

    return nextState;
  },
  setActiveOrg: async ({activeOrgId, getState}) => {
    var nextState = {..._.get(getState(), `session`)};
    var {activeOrg} = await activeOrgFor({activeOrgId, orgs: nextState.orgs});

    nextState = {...nextState, activeOrg};

    return nextState;
  },
  createOrg: async ({props, getState}) => {
    var nextState = {..._.get(getState(), `session`)};

    try {
      var {data} = await lib.api.request({uri: '/orgs/create', body: {props}});
      var newOrg = data.org;

      nextState = {...nextState, orgs: [...nextState.orgs, newOrg], org: newOrg};
    }
    catch (error) {
      nextState = {...nextState, error};
    }

    return nextState;
  },
  clearError: ({getState}) => {
    var nextState = {..._.get(getState(), `session`)};

    _.unset(nextState, 'error');
    _.unset(nextState, 'errors');

    return nextState;
  }
});

var {logIn, signUp, logOut, updateMyAccount, resetPassword, acceptOrgInvite, createOrg, deleteOrg, setActiveOrg, clearError, updateUserProps, setProtagonistData, setAppData, updateOrg, updateOrgs} = actions;

function createStore({reducers, ...params}={}) {
  reducers = {
    session: reducer,
    ...reducers
  };

  return createGenericStore({reducers, ...params});
}

//['stories', 'nodes', 'edges', 'actions']
var resourcesReducerAndActionsFor = resourcesData => {
  var reducerAndActionsByResource = {};

  _.forEach(resourcesData, ({indexedFieldKeys=[]}, pKey) => {
    var sKey = inflection.singularize(pKey);

    var actionKeyFor = (action, key) => _.camelCase(`${action}-${_.kebabCase(key)}`);

    var stateFor = (state, {prevState, oldResources, resources, actionKey}) => {
      if (pKey === 'edges') state.parentMap = _.keyBy(by.id, node => `${node.fromNodeId}-${node.toNodeId}`);

      ///HINT i.e. resources.stories.byFieldKeyIndex.parentFieldId.16 = {2: {id: 2}, 3: {id: 3}}
      //WARNING performance is sensitive here because of how many loops that are happening on each common action
      //WARNING there are two levels of indexes here - the key fieldKey, and the possible field values, i.e. {parentFieldId: 16}, {parentFieldId: 17} etc
      _.forEach(indexedFieldKeys, fieldKey => {
        var nextIndex = {..._.get(prevState, `byFieldKeyIndex.${fieldKey}`)};
        var nextIndexChanged = false;

        if (_.includes(['destroy', 'update', 'track'], actionKey)) {
          _.forEach(oldResources, oldResource => {
            var prevFieldIndex = nextIndex[oldResource[fieldKey]];

            if (prevFieldIndex && prevFieldIndex[`${oldResource.id}`]) {
              nextIndexChanged = true;

              var nextFieldIndex = nextIndex[oldResource[fieldKey]] = {...prevFieldIndex};

              delete nextFieldIndex[`${oldResource.id}`];
            }
          });
        }

        if (_.includes(['track', 'create', 'update'], actionKey)) {
          nextIndexChanged = true;

          _.forEach(resources, resource => {
            var fieldIndex = nextIndex[resource[fieldKey]] = {...nextIndex[resource[fieldKey]]};

            //WARNING intentionally not spreading {...resource} to reduce memory
            fieldIndex[`${resource.id}`] = resource;
          });
        }

        if (nextIndexChanged) {
          _.set(state, `byFieldKeyIndex.${fieldKey}`, nextIndex);
        }
      });

      state.id = _.uniqueId();

      return state;
    };

    reducerAndActionsByResource[pKey] = reducerAndActionsFor(`resources.${pKey}`, {
      [actionKeyFor('track', pKey)]: async ({params, reset=false, getState, ...args}) => { //effectively a "push"
        var resources = args[pKey];
        var prevState = reset ? {byId: {}, byFieldKeyIndex: {}} : _.get(getState(), `resources.${pKey}`, {});
        var byId = {...prevState.byId, ..._.keyBy(resources, 'id')};
        var oldResources = reset ? [] : _.values(_.pick(prevState.byId, _.map(resources, ({id}) => `${id}`)));

        return stateFor({byId}, {prevState, oldResources, resources, actionKey: 'track'});
      },
      [actionKeyFor('create', sKey)]: async ({props, resource, files, hitApi=true, getState}) => {
        if (hitApi) resource = await lib.api.create(sKey, _.omit(props, ['id']), {files});

        var prevState = _.get(getState(), `resources.${pKey}`, {});
        var byId = {...prevState.byId, [resource.id]: resource};

        return stateFor({byId}, {prevState, resources: [resource], actionKey: 'create'});
      },
      [actionKeyFor('create', pKey)]: async ({propsSets, resources, files, hitApi=true, getState}) => {
        if (hitApi) resources = await lib.api.create(pKey, _.map(propsSets, props => _.omit(props, ['id'])), {files});

        var prevState = _.get(getState(), `resources.${pKey}`, {});
        var byId = {...prevState.byId, ..._.keyBy(resources, 'id')};

        return stateFor({byId}, {prevState, resources, actionKey: 'create'});
      },
      [actionKeyFor('update', sKey)]: async ({id, props, hitApi=true, getState}) => {
        if (hitApi) lib.api.update(sKey, {where: {id}, props});

        var prevState = _.get(getState(), `resources.${pKey}`, {});
        var oldResources = prevState.byId;
        var oldResource = oldResources[id];
        var resource = {...oldResource, ...props};
        var byId = {...oldResources, [id]: resource};

        return stateFor({byId}, {prevState, oldResources: [oldResource], resources: [resource], actionKey: 'update'});
      },
      [actionKeyFor('update', pKey)]: async ({ids, props, updates, files, hitApi=true, getState}) => {
        if (hitApi) lib.api.update(pKey, updates ? updates : {where: {id: ids}, props}, {files});

        var prevState = _.get(getState(), `resources.${pKey}`, {});
        var allOldResources = prevState.byId;
        var byId = {...allOldResources};
        var oldResources = [], resources = [];

        if (ids && props) updates = _.map(ids, id => ({where: {id}, props}));

        _.forEach(updates, ({props, where}) => {
          var oldResource = allOldResources[where.id];
          var resource = byId[where.id] = {...oldResource, ...props};

          oldResources.push(oldResource);
          resources.push(resource);
        });

        return stateFor({byId}, {prevState, oldResources, resources, actionKey: 'update'});
      },
      [actionKeyFor('destroy', sKey)]: async ({id, hitApi=true, getState}) => {
        if (hitApi) lib.api.destroy(sKey, {where: {id}});

        var prevState = _.get(getState(), `resources.${pKey}`, {});
        var allOldResources = prevState.byId;
        var byId = _.omit(allOldResources, [id + '']);

        return stateFor({byId}, {prevState, oldResources: [allOldResources[`${id}`]], actionKey: 'destroy'});
      },
      [actionKeyFor('destroy', pKey)]: async ({ids, hitApi=true, getState}) => {
        if (hitApi) lib.api.destroy(pKey, {where: {id: ids}});

        var prevState = _.get(getState(), `resources.${pKey}`, {});
        var allOldResources = prevState.byId;
        var stringIds = _.map(ids, id => `${id}`);
        var byId = _.omit(allOldResources, stringIds);

        return stateFor({byId}, {prevState, oldResources: _.values(_.pick(allOldResources, stringIds)), actionKey: 'destroy'});
      }
    });
  });

  var reducer = redux.combineReducers(_.mapValues(reducerAndActionsByResource, 'reducer'));
  var actions = _.mapValues(reducerAndActionsByResource, 'actions');

  return {reducer, actions};
};

var attachmentSchema = {
  resourceKey: 'attachment',
  propKeys: ['uploaderId', 'plainFileName', 'fileExtension', 'fileSize', 'created'],
  parents: {story: {}, user: {}},
  mapState: () => ({}),
  propertyDefinitions: {}
};

var lastStateId, cache;

var cachedValueFor = (key, {storyId, stateId}, computeValue) => {
  var value;

  if (stateId !== lastStateId) {
    cache = {
      sizeInMinutesByStoryId: {},
      computedSizeInMinutesByStoryId: {},
      remainingSizeInMinutesByStoryId: {},
      storyWorkersByStoryId: {},
      fillerStoriesByParentStoryId: {}
    };

    lastStateId = stateId;
  }
  else {
    value = cache[key][storyId];

    if (value !== undefined) return value;
  }

  value = computeValue();

  cache[key][storyId] = value;

  return value;
};

var sizeInMinutesFor = ({storyId, ...dependencies}) => {
  var {stateId, storiesById} = dependencies;

  return cachedValueFor('sizeInMinutesByStoryId', {storyId, stateId}, () => {
    var userEnteredSizeInMinutes = storiesById[storyId].sizeInMinutes;
    var computedSizeInMinutes = computedSizeInMinutesFor({storyId, ...dependencies});
    var useComputed = computedSizeInMinutes >= userEnteredSizeInMinutes * 0.9;

    return useComputed ? computedSizeInMinutes : userEnteredSizeInMinutes;
  });
};

var computedSizeInMinutesFor = ({storyId, ...dependencies}) => {
  var {stateId, storiesByParentStoryId} = dependencies;

  return cachedValueFor('computedSizeInMinutesByStoryId', {storyId, stateId}, () => {
    var childStories = storiesByParentStoryId[storyId];
    var computedSizeInMinutes = 0;

    for (var childStoryId in childStories) {
      var {status} = childStories[childStoryId];

      if (status === 'complete' || status === 'ready' || status === 'planning' || status === 'active') {
        computedSizeInMinutes += sizeInMinutesFor({storyId: childStoryId, ...dependencies});
      }
    }

    return computedSizeInMinutes;
  });
};

//HINT i.e. 4w parent, w/ 1w complete child, 2w ready child (1.5w remaining) -> 4w - 1w - 0.5w (netIncompleteDelta)
var remainingSizeInMinutesFor = ({storyId, ...dependencies}) => {
  var {stateId, storiesByParentStoryId} = dependencies;

  return cachedValueFor('remainingSizeInMinutesByStoryId', {storyId, stateId}, () => {
    var childStories = storiesByParentStoryId[storyId] || {};
    var remainingSizeInMinutes = sizeInMinutesFor({storyId, ...dependencies});

    for (var childStoryId in childStories) {
      var {status} = childStories[childStoryId];

      if (status === 'complete' || status === 'ready' || status === 'planning' || status === 'active') {
        remainingSizeInMinutes -= sizeInMinutesFor({storyId: childStoryId, ...dependencies});
      }

      if (status === 'ready' || status === 'planning' || status === 'active') {
        remainingSizeInMinutes += remainingSizeInMinutesFor({storyId: childStoryId, ...dependencies});
      }
    }

    return remainingSizeInMinutes;
  });
};

var storyWorkersFor = ({storyId, snoozeDate, ...dependencies}) => {
  var {stateId, storyTeamMembersByStoryId, storiesByParentStoryId, parentStories} = dependencies;

  var getStoryWorkers = ({storyId, stateId, snoozeDate, mostRecentParentTeamMembers}) => cachedValueFor('storyWorkersByStoryId', {storyId, stateId}, () => {
    var relevantChildStories = _.reject(storiesByParentStoryId[storyId], ({status}) => _.includes(['complete', 'archived'], status));
    var storyTeamMembers = storyTeamMembersByStoryId[storyId] || mostRecentParentTeamMembers || {null: {userId: null}};
    var storyWorkers;

    if (_.isEmpty(relevantChildStories)) {
      storyWorkers = _.size(storyTeamMembers) > 0 ? [{userIds: _.map(storyTeamMembers, 'userId'), snoozeDate, workProportion: 1}] : [];
    }
    else {
      var workProportionsByUserIdKey = {};

      var parentSnoozeDate = snoozeDate;

      var allStoryWorkers = _.flatMap(relevantChildStories, ({id, snoozeDate}) => {
        snoozeDate = (parentSnoozeDate && !snoozeDate) || (new Date(snoozeDate).getTime() < new Date(parentSnoozeDate).getTime()) ? parentSnoozeDate : snoozeDate;

        return getStoryWorkers({storyId: id, snoozeDate, stateId, mostRecentParentTeamMembers: storyTeamMembers});
      });

      _.forEach(allStoryWorkers, ({userIds, snoozeDate, workProportion}) => {
        var userIdKey = userIds[0] !== null ? _.join(userIds.sort(), '-') : 'null';
        workProportionsByUserIdKey[userIdKey] = workProportionsByUserIdKey[userIdKey] || {};

        if (!snoozeDate) snoozeDate = null;

        workProportionsByUserIdKey[userIdKey][snoozeDate] = workProportionsByUserIdKey[userIdKey][snoozeDate] || 0;

        workProportionsByUserIdKey[userIdKey][snoozeDate] += workProportion / _.size(relevantChildStories);
      });

      storyWorkers = _.flatMap(workProportionsByUserIdKey, (workProportions, userIdKey) => {
        var userIds = userIdKey !== 'null' ? _.map(_.split(userIdKey, '-'), _.parseInt) : [null];

        return _.map(workProportions, (workProportion, snoozeDate) => {
          snoozeDate = snoozeDate === 'null' ? null : snoozeDate;

          return {userIds, userIdKey, workProportion, snoozeDate};
        })
      });
    }

    return storyWorkers;
  });

  if (!_.isEmpty(parentStories)) {
    var {id: topLevelParentId} = _.last(parentStories);

    getStoryWorkers({storyId: topLevelParentId, snoozeDate, stateId});
  }

  return getStoryWorkers({storyId, snoozeDate, stateId});
};

var fillerStoriesFor = ({storyId, ...dependencies}) => {
  var {stateId, storiesById, storiesByParentStoryId} = dependencies;

  return cachedValueFor('fillerStoriesByParentStoryId', {storyId, stateId}, () => {
    var fillerStories = [];
    var childStories = storiesByParentStoryId[storyId];

    var unspecifiedSizeInMinutes = storiesById[storyId].sizeInMinutes - _.sumBy(_.reject(childStories, {status: 'archived'}), 'sizeInMinutes');

    if (unspecifiedSizeInMinutes > 0 && !_.isEmpty(childStories)) {
      var storyWorkers = storyWorkersFor({storyId, ...dependencies});

      fillerStories = _.map(storyWorkers, storyWorker => storyWorker.workProportion * unspecifiedSizeInMinutes);
    }

    return fillerStories;
  });
};

var storySchema = {
  resourceKey: 'story',
  propKeys: ['title', 'status', 'description', 'isBlocking', 'priority', 'sizeInMinutes', 'parentStoryId', 'deadlineDate', 'snoozeDate'],
  children: {attachments: {},  comments: {}, storyTeamMembers: {}},
  propertyDefinitions: {
    //< relatives
    parentStory: {
      get() {
        return this.resourcesById[this.parentStoryId];
      }
    },
    parentStories: {
      get() {
        var parentStories = [];
        var {parentStory} = this;
        var {resourcesById} = this;

        while (parentStory) {
          parentStories.push(parentStory);

          parentStory = resourcesById[parentStory.parentStoryId];
        }

        return parentStories;
      }
    },
    childStories: {
      get() {
        return _.values(this.props.getResourceState().byFieldKeyIndex.parentStoryId[this.id]);
      }
    },
    inclusiveSiblingStories: {
      get() {
        return _.values(this.props.getResourceState().byFieldKeyIndex.parentStoryId[this.parentStoryId]);
      }
    },
    exclusiveSiblingStories: {
      get() {
        return _.reject(_.values(this.props.getResourceState().byFieldKeyIndex.parentStoryId[this.props.parentStoryId]), {id: this.id});
      }
    },
    allChildStories: {
      get() {
        var storiesByParentStoryId = _.get(this.props.getResourceState(), 'byFieldKeyIndex.parentStoryId', {});

        var childStoriesFor = ({id}) => {
          var childStories = _.values(storiesByParentStoryId[id]);

          return [...childStories, ..._.flatMap(childStories, ({id}) => childStoriesFor({id}))];
        };

        return childStoriesFor({id: this.id});
      }
    },
    //> relatives

    //< size
    sizeDependencies: {
      get() {
        var storiesState = this.props.getResourceState();
        var stateId = storiesState.id;
        var storiesByParentStoryId = _.get(storiesState, 'byFieldKeyIndex.parentStoryId', {});
        var storiesById = storiesState.byId || {};
        var storyTeamMembersByStoryId = this.props.store.getState().resources.storyTeamMembers.byFieldKeyIndex.storyId;
        var parentStories = this.parentStories;

        return {stateId, storiesByParentStoryId, storiesById, storyTeamMembersByStoryId, parentStories};
      }
    },
    isAutosized: {
      get() {
        return _.reject(this.childStories, {status: 'archived'}).length === 0 || this.computedSizeInMinutes >= this.resourceProps.sizeInMinutes * 0.9;
      }
    },
    sizeInMinutes: {
      get() {
        return sizeInMinutesFor({storyId: this.id, ...this.sizeDependencies});
      }
    },
    computedSizeInMinutes: {
      get() {
        return computedSizeInMinutesFor({storyId: this.id, ...this.sizeDependencies});
      }
    },
    remainingSizeInMinutes: {
      get() {
        return remainingSizeInMinutesFor({storyId: this.id, ...this.sizeDependencies});
      }
    },
    //> size

    indexInColumn: {
      get() {
        var sortedSiblings = _.sortBy(this.inclusiveSiblingStories, [
          ({status}) => ({complete: 3, archived: 4}[status] || 0),
          'priority',
          'id'
        ]);

        return _.map(sortedSiblings, 'id').indexOf(this.id);
      }
    },
    initials: {
      get () {
        var title = this.title || '';
        var words = _.map(_.split(title, ' '), word => word.replace(/[\W_]+/g, ''));
        var initials = '';

        if (words.length === 1) {
          initials = words[0].substring(0, 1);
        }
        else {
          initials = _.join(_.map(_.filter(words, (word, w) => w < 2 && word.length > 0), word => word[0]), '');
        }

        return initials;
      }
    },
    storyWorkers: {
      get() {
        return storyWorkersFor({storyId: this.id, snoozeDate: this.snoozeDate, ...this.sizeDependencies});
      }
    },
    fillerStories: {
      get() {
        return fillerStoriesFor({storyId: this.id, ...this.sizeDependencies});
      }
    }
  }
};

var commentSchema = {
  resourceKey: 'comment',
  propKeys: ['authorUserId', 'body', 'created', 'parentCommentId'],
  parents: {story: {}, user: {}},
  mapState: () => ({}),
  propertyDefinitions: {
    // parentComment: {
    //   get() {
    //     return ''; // TODO
    //   }
    // }
  }
};

var storyTeamMemberSchema = {
  resourceKey: 'storyTeamMember',
  propKeys: ['storyId', 'userId', 'maxFocusProportion'],
  parents: {story: {}, user: {}},
  propertyDefinitions: {
    activeFocusProportion: {
      get() {
        return 0; //TODO
      }
    },
    maxFocusProportion: {
      get() {
        return 0; //TODO
      }
    }
  }
};

var userSchema = {
  resourceKey: 'user',
  propKeys: ['name'],
  children: {attachments: {}, comments: {}, storyTeamMembers: {}},
};

var notificationSchema = {
  resourceKey: 'notification',
  propKeys: ['userId', 'resourceKey', 'resourceId', 'body', 'type', 'data', 'status'],
  parents: {user: {}}, // TODO
  mapState: () => ({}), // TODO
  propertyDefinitions: {
    // TODO
  }
};

//HINT this is a class generator that allows us to create components that are simpler in the way they interact with redux
//HINT it assists with reading data, and updating it in a way that is very accessible for the component implementation
//HINT you generally use it when you have a component that corresponds to an api resource
//HINT it's more powerful when that component is interactive (create, update, destroy), but it's also useful just for connecting to state
//WARNING this is in a kind of weird place

/*

storyFor
storiesFor
storyModelFor
storyModelsFor

Story : a plain class for utilizing getters/functionality an object from state wouldn't be able to have
  updateStory({propKey: '', value, props})
  destroyStory()

class Subclass extends StoryComponent : a react component for rendering a single resource - i.e. one story
  ...everything in Story
  static connect(Subclass)

class Subclass extends StoriesComponent : a react component for rendering a collection of resources - i.e. many stories
  createStory({props: {}})
  getAndTrackStories()
  static connect(Subclass, {})


*/

//TODO error handling
var schemas = {story: storySchema, attachment: attachmentSchema, comment: commentSchema, storyTeamMember: storyTeamMemberSchema, user: userSchema, notification: notificationSchema};

function resourceClassesFor(schemaKeys, {store, resourceActions, Component}) {
  var resourceClasses = {};

  _.forEach(schemas, (schema) => {
    var {resourceKey, propKeys, children, propertyDefinitions, mapState: schemaMapState, mapDispatch: schemaMapDispatch} = schema;
    var pKey = inflection.pluralize(resourceKey), sKey = inflection.singularize(resourceKey);

    var getResourceState = () => store.getState().resources[pKey]; //TODO cache in-between changes

    if (_.includes(schemaKeys, pKey)) {
      //HINT updateResource -> updateStory
      var genericKeyMap = {
        updateResource: _.camelCase(`update-${resourceKey}`),
        createResource: _.camelCase(`create-${resourceKey}`),
        destroyResource: _.camelCase(`destroy-${resourceKey}`),
        updateResources: _.camelCase(`update-${pKey}`),
        createResources: _.camelCase(`create-${pKey}`),
        destroyResources: _.camelCase(`destroy-${pKey}`),
        trackResources: _.camelCase(`create-${pKey}`)
      };

      //<---------- helper functions
      var resourceFor = resourceClasses[`${sKey}For`] = ({id}) => getResourceState().byId[id];

      resourceClasses[`${pKey}For`] = ({ids}) => {
        var resourcesById = getResourceState().byId;

        return _.filter(_.map(ids, id => resourcesById[id]), resource => !!resource);
      };

      //modelFor(story) || modelFor({id})
      var resourceModelFor = resourceClasses[`${sKey}ModelFor`] = (arg) => {
        if (arg) {
          var {id} = arg;

          return new ResourceModel({id, getResourceState, store});
        }
      };

      //modelsFor(stories) || modelsFor({ids: []})
      var resourceModelsFor = resourceClasses[`${sKey}ModelsFor`] = (arg) => {
        var ids = Array.isArray(arg) ? _.map(arg, 'id') : arg.ids;

        return _.map(ids, id => resourceModelFor({id}));
      };
      //----------> helper functions

      //<---------- StoryModel
      class ResourceModel {
        constructor(props) {
          this.props = props;
          this.id = this.props.id;
        }

        get resourcesById() {
          return getResourceState().byId;
        }

        get resourceProps() {
          return getResourceState().byId[this.props.id];
        }
      }

      _.forEach(propertyDefinitions, (definition, key) => {
        Object.defineProperty(ResourceModel.prototype, key, definition);
      });

      //HINT propKeys: ['title', 'status', ...] -> this.status is now a getter that reads this.props.story.status safely
      _.forEach(propKeys, (propKey) => {
        if (!ResourceModel.prototype.hasOwnProperty(propKey)) {
          Object.defineProperty(ResourceModel.prototype, propKey, {
            get() {
              return getResourceState().byId[this.props.id][propKey];
            }
          });
        }
      });

      //TODO parents
      _.forEach(children, (_settings, childResourceKey) => {
        Object.defineProperty(ResourceModel.prototype, childResourceKey, {
          get() {
            var state = store.getState();
            var children = _.filter(_.get(state.resources[inflection.pluralize(childResourceKey)], 'byId'), {[`${inflection.singularize(resourceKey)}Id`]: this.props.id});
            //TODO optimize w byFieldKeyIndex

            return children;
          }
        });
      });

      //----------> helper functions

      //<---------- StoryComponent
      class ResourceComponent extends Component {

      }

      //this.story
      Object.defineProperty(ResourceComponent.prototype, sKey, {
        get() {
          return resourceFor({id: this.props.id});
        }
      });

      //this.storyModel
      Object.defineProperty(ResourceComponent.prototype, `${sKey}Model`, {
        get() {
          return resourceModelFor({id: this.props.id});
        }
      });

      ResourceComponent.prototype[genericKeyMap.updateResource] = function({propKey, value, props}) {
        if (!props) props = {[propKey]: value};

        this.props[genericKeyMap.updateResource]({id: this.props.id, props});
      };
      ResourceComponent.prototype[genericKeyMap.destroyResource] = function() {
        this.props[genericKeyMap.destroyResource]({id: this.props.id});
      };
      ResourceComponent.prototype[genericKeyMap.createResource] = function({props={}}={}) {
        if (!props.orgId) props = {...props, orgId: this.props.session.activeOrg.id};

        this.props[genericKeyMap.createResource]({props});
      };

      //HINT export default StoryComponent.connect(Properties)
      ResourceComponent.connect = function(Subclass, {mapState: functionMapState, mapDispatch: functionMapDispatch}={}) {
        return connect({
          mapState: (state, ownProps) => {
            var props = {session: state.session};
            var resource = _.get(state.resources[pKey], `byId.${ownProps.id}`);

            props[resourceKey] = resource;

            if (functionMapState) props = {...props, ...functionMapState(state, ownProps)};
            if (schemaMapState) props = {...props, ...schemaMapState(state, ownProps)};

            return props;
          },
          mapDispatch: {
            ..._.pick(resourceActions[pKey], _.values(_.pick(genericKeyMap, [
              'updateResource', 'createResource', 'destroyResource'
            ]))),
            ...schemaMapDispatch,
            ...functionMapDispatch
          }
        })(Subclass);
      };
      //----------> StoryComponent

      //<---------- StoriesComponent
      class ResourcesComponent extends Component {

      }

      ResourcesComponent.prototype[genericKeyMap.createResource] = function({props={}}={}) {
        if (!props.orgId) props = {...props, orgId: this.props.session.activeOrg.id};

        this.props[genericKeyMap.createResource]({props});
      };

      //this.stories
      Object.defineProperty(ResourcesComponent.prototype, pKey, {
        get() {
          return _.values(this.props[pKey]);
        }
      });

      //this.storyModels
      Object.defineProperty(ResourcesComponent.prototype, `${sKey}Models`, {
        get() {
          return resourceModelsFor(_.values(this.props[pKey]));
        }
      });

      ResourcesComponent.connect = function(Subclass, {
        resourcesFilterDataFor, mapState: functionMapState, mapDispatch: functionMapDispatch
      } = {}) {
        return connect({
          mapState: (state, ownProps) => {
            var props = {session: state.session}, resources;
            var resourcesState = state.resources[pKey];

            if (resourcesFilterDataFor) {
              var filterData = resourcesFilterDataFor({ownProps});

              if (filterData.mode === 'filter') {
                resources = _.filter(_.get(resourcesState, `byId`), resource => filterData.filter({resource}));
              }
              else if (filterData.mode === 'fieldKeyIndex') {
                resources = _.get(resourcesState, `byFieldKeyIndex.${filterData.key}[${filterData.value}]`, {});
              }
              else if (filterData.mode === 'byId') {
                resources = _.filter(_.map(filterData.ids, id => _.get(resourcesState, `byId[${id}]`)), resource => !!resource);
              }
            }
            else {
              resources = _.get(resourcesState, `byId`, []);
            }

            props[pKey] = resources;

            if (functionMapState) props = {...props, ...functionMapState(state, ownProps)};
            if (schemaMapState) props = {...props, ...schemaMapState(state, ownProps)};

            return props;
          },
          mapDispatch: {
            ..._.pick(resourceActions[pKey], _.values(_.pick(genericKeyMap, [
              'trackResources', 'updateResources', 'createResource', 'destroyResources'
            ]))),
            ...schemaMapDispatch,
            ...functionMapDispatch
          }
        })(Subclass);
      };
      //----------> StoriesComponent

      var singularClassPrefix = _.upperFirst(_.camelCase(sKey));
      var pluralClassPrefix = _.upperFirst(_.camelCase(pKey));

      resourceClasses[`${singularClassPrefix}Component`] = ResourceComponent;
      resourceClasses[`${pluralClassPrefix}Component`] = ResourcesComponent;
    }
  });

  return resourceClasses;
}

var pollInterval;

function pollApiForChanges({store, resources, prepareToAnimate, getShouldPoll, pollFrequency}) {
  var lastResourceChangesRequestTime = moment().utc();

  var setPollInterval = () => {
    if (pollInterval) clearInterval(pollInterval);

    pollInterval = setInterval(() => {
      if (!getShouldPoll || getShouldPoll()) poll();
    }, pollFrequency || 5 * 1000);
  };

  var poll = async () => {
    var state = store.getState();
    var isLoading = false;
    var currentlyPolledResources = {};

    _.forEach(resources, (resource, resourceKey) => {
      if (resource.getShouldPoll ? resource.getShouldPoll() : true) {
        currentlyPolledResources[resourceKey] = resource;
      }
    });

    if (!isLoading) {
      var requestTime = moment().utc();

      isLoading = true;

      var updatedResources = await lib.api.request({uri: '/poll', body: {
        resources: _.mapValues(currentlyPolledResources, ({getData}) => getData ? getData() : {}),
        lastResourceChangesRequestTime: lastResourceChangesRequestTime.format('YYYY-MM-DD HH:mm:ss')
      }}, {shouldAlert: false, isPolling: true});

      //HINT update poll frequency if api says it has changed
      if (updatedResources.data.pollFrequency && pollFrequency !== updatedResources.data.pollFrequency) {
        console.log('updated poll frequency', pollFrequency, updatedResources.data.pollFrequency);

        pollFrequency = updatedResources.data.pollFrequency;

        setPollInterval();
      }

      isLoading = false;
      lastResourceChangesRequestTime = requestTime;

      var changesMade = false;

      _.forEach(currentlyPolledResources, (resource, resourceKey) => {
        try {
          var {[resourceKey]: updatedResourceData} = updatedResources.data;

          var changeFound = false;
          var destroyedResourceIds = [], createdOrUpdatedResources = [];

          _.forEach(updatedResourceData, updatedResource => {
            var oldResource = state.resources[resourceKey].byId[updatedResource.id];

            if (updatedResource.deleted) {
              if (oldResource) destroyedResourceIds.push(updatedResource.id);
            }
            else if (!oldResource) {
              createdOrUpdatedResources.push(updatedResource);
            }
            else {
              var omit = (_resource) => _.omit(_resource, ['lastUpdated', 'created', 'deleted']);

              if (!_.isEqual(omit(updatedResource), omit(oldResource))) {
                createdOrUpdatedResources.push(updatedResource);
              }
            }
          });

          if (destroyedResourceIds.length) {
            changeFound = true;

            resource[`destroy${_.upperFirst(inflection.pluralize(resourceKey))}`]({ids: destroyedResourceIds, hitApi: false});
          }

          if (createdOrUpdatedResources.length) {
            changeFound = true;

            resource[`track${_.upperFirst(inflection.pluralize(resourceKey))}`]({[resourceKey]: createdOrUpdatedResources});
          }

          if (changeFound) changesMade = true;
        }
        catch (error) {
          console.error(error);
        }
      });

      if (changesMade && prepareToAnimate) prepareToAnimate();
    }
  };

  //TODO check for active redux requests

  setPollInterval();

  return pollInterval;
}

exports.acceptOrgInvite = acceptOrgInvite;
exports.clearError = clearError;
exports.connect = connect;
exports.createGenericStore = createGenericStore;
exports.createOrg = createOrg;
exports.createStore = createStore;
exports.deleteOrg = deleteOrg;
exports.logIn = logIn;
exports.logOut = logOut;
exports.pollApiForChanges = pollApiForChanges;
exports.reducerAndActionsFor = reducerAndActionsFor;
exports.resetPassword = resetPassword;
exports.resourceClassesFor = resourceClassesFor;
exports.resourcesReducerAndActionsFor = resourcesReducerAndActionsFor;
exports.sessionReducer = reducer;
exports.setActiveOrg = setActiveOrg;
exports.setAppData = setAppData;
exports.setProtagonistData = setProtagonistData;
exports.signUp = signUp;
exports.updateMyAccount = updateMyAccount;
exports.updateOrg = updateOrg;
exports.updateOrgs = updateOrgs;
exports.updateUserProps = updateUserProps;
